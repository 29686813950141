import React, { useEffect, useState, Suspense, lazy, useLayoutEffect } from 'react'

//router
import { Switch, Route } from 'react-router-dom'
import { tenant, eventconfig, exchangerate, productfilter, popup } from '../utilities/apiconfig'
import { defaultGET, get } from '../api'
import axios from "axios";
import { store } from '../store'
import {
    STORE_TENANT, STORE_EVENT_CONFIG, STORE_BROWSER_DATA, STORE_SHOP_FILTER, STORE_POPUPS, STORE_THEME_COLORS
} from '../store/actiontypes'
import { checkNull, customLogin, setSessionIdCookie, getBrowserData, isAuthenticated, scheduleTokenRefresh, setDeviceIdCookie } from '../utilities/generic'
import constant from '../utilities/constant'
import { Modal } from 'react-bootstrap';
import { BiWifiOff } from 'react-icons/bi';
import PopUpModal from '../layouts/dashboard/modals/popupmodal'
import Loading from '../utilities/loading/loading';

const Default = lazy(() => import('../layouts/dashboard/default'));
const CustomBoard = lazy(() => import('../layouts/dashboard/custom'));
const Simple = lazy(() => import('../layouts/dashboard/simple'));
const ShopbuzzRouter = lazy(() => import("../features/shopbuzz/products/shopbuzz"));
const ProductDetails = lazy(() => import('../features/shopbuzz/packages/packagedetails'));
const ContestView = lazy(() => import('../features/contests/contestview'));
const QuizDetails = lazy(() => import('../features/opinions/quizzes/quizDetails'));
const TakeSurvey = lazy(() => import('../features/opinions/surveys/TakeSurvey'));
const BlogDetails = lazy(() => import('../features/blogs/blogsdetails'));
const EventDetails = lazy(() => import('../features/events/eventdetails'));
const EventBoard = lazy(() => import('../features/events/eventboard'));
const WalletBoard = lazy(() => import('../features/profile/wallet/walletboard'));
const HelpBoard = lazy(() => import('../features/help/helpboard'));
const TutorialBoard = lazy(() => import('../features/help/tutorial/tutorialboard'));
const SocialMediaRoute = lazy(() => import('../features/social/socialmedia-router'));
const Reels = lazy(() => import('../features/reels/reels'));
const ScratchCardRoute = lazy(() => import('../features/scratchcards/scratchcard-router'));
const Importcontent = lazy(() => import('../features/social/importcontent'));
const Unsubscribe = lazy(() => import('../features/profile/settings/unsubscribe'));
const Prototypescreen = lazy(() => import('../components/prototypescreen'));
const Generateqr = lazy(() => import('../components/generateqr'));
const MobileSideBar = lazy(() => import('../components/mobilesidebar'));
const Emailverification = lazy(() => import('../features/profile/login/emailverification'));
const PrivateRoute = lazy(() => import('./private-router'));
const Voucherlist = lazy(() => import('../features/promotions/vouchers/voucherlist'));
const GroupBoard = lazy(() => import('../features/groups/groupboard'));
const ChatBoard = lazy(() => import('../features/chat/chatboard'));

const IndexRouters = (props) => {

    const [isInternetDown, setIsInternetDown] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = urlParams.get('jwtToken')

    useEffect(() => {
        let tokenMounted = false
        let access_token = localStorage.getItem(constant.access_token)
        if(!access_token) {
            setSessionIdCookie()
        }
        if (checkNull(access_token) !== "" && !tokenMounted) {
            let expires_in = localStorage.getItem(constant.expires_in)
            setSessionIdCookie(access_token)

            scheduleTokenRefresh(expires_in)
        }

        setDeviceIdCookie()

        window.addEventListener('online', () => setIsInternetDown(false), false);
        window.addEventListener('offline', () => setIsInternetDown(true), false);

        return () => {
            tokenMounted = true
        }
    }, []);

    useEffect(() => {
        async function cLogin() {
            if(checkNull(jwtToken) === ''){
                return;
            }
            await customLogin(jwtToken)
        }
        cLogin()
    }, [jwtToken])

    useEffect(() => {

        async function getPopUpData() {
            setInterval(async () => {
                if (store.getState().data.popupList.length === 0) {
                    let poup_data = await get(popup)
                    let data = poup_data?.data
                    if (data) {
                        store.dispatch({ type: STORE_POPUPS, payload: data })
                    }
                }
            }, 60000)
        }
        getPopUpData();
    }, [])

    useLayoutEffect(() => {
        let tenantMount = false;
        async function fetchTenantData() {
            const tenant_data = await get(`${tenant}`)
            const data = tenant_data?.data

            if (data && !tenantMount) {
                localStorage.setItem(constant.logo, data.logoUrl)
                localStorage.setItem(constant.privacy_policy, data.privacyUrl)
                localStorage.setItem(constant.company_name, data.name)
                localStorage.setItem(constant.tenantType, data.tenantProductType)

                localStorage.setItem(constant.primaryColor, data?.primaryColor)
                localStorage.setItem(constant.interactiveColor, data?.interactiveColor)
                localStorage.setItem(constant.secondaryColor, data?.secondaryColor)
                localStorage.setItem(constant.neutralColor, "#fff")

                store.dispatch({ type: STORE_THEME_COLORS, payload: data })
                store.dispatch({ type: STORE_TENANT, payload: data })

                document.body.style.backgroundColor = data.screenBackground

                try {
                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }

                    link.href = data?.faviconUrl;
                } catch (e) { }
            }
        }
        fetchTenantData();

        return () => {
            tenantMount = true;
        }
    }, []);

    useEffect(() => {
        let eConfig = false;
        async function getEventConfigValues() {
            if (isAuthenticated()) {
                let event_config_url = `${eventconfig}`
                let event_config_val = await get(event_config_url)
                if (event_config_val.status && !eConfig) {
                    let data = event_config_val.data
                    if (checkNull(data) !== "") {
                        store.dispatch({ type: STORE_EVENT_CONFIG, payload: data })
                    }
                }
            }
        }
        getEventConfigValues();

        return () => {
            eConfig = true;
        }
    }, []);

    useEffect(() => {
        let currencyMount = false;
        async function getBrowserCurrency() {
            let usdRate = localStorage.getItem(constant.USD_RATE)
            if (checkNull(usdRate) !== "" || currencyMount) {
                return;
            }

            let browserData = await getBrowserData()
            store.dispatch({ type: STORE_BROWSER_DATA, payload: browserData })
            let currencyCalls = []
            let inrRate = 1, eurRate = 1, gbpRate = 1;
            usdRate = 1
            let currency_inr_url = `${exchangerate}convert?access_key=${constant.Exchangerate_API_KEY}&from=INR&to=${browserData.currency}&amount=1`
            currencyCalls.push(defaultGET(currency_inr_url))
            let currency_eur_url = `${exchangerate}convert?access_key=${constant.Exchangerate_API_KEY}&from=EUR&to=${browserData.currency}&amount=1`
            currencyCalls.push(defaultGET(currency_eur_url))
            let currency_usd_url = `${exchangerate}convert?access_key=${constant.Exchangerate_API_KEY}&from=USD&to=${browserData.currency}&amount=1`
            currencyCalls.push(defaultGET(currency_usd_url))
            let currency_gbp_url = `${exchangerate}convert?access_key=${constant.Exchangerate_API_KEY}&from=GBP&to=${browserData.currency}&amount=1`
            currencyCalls.push(defaultGET(currency_gbp_url))
            axios.all(currencyCalls).then((responses) => {
                responses.forEach((_res, index) => {
                    let rate = _res?.data?.result
                    if (index === 0) {
                        inrRate = rate
                    } else if (index === 1) {
                        eurRate = rate
                    } else if (index === 2) {
                        usdRate = rate
                    } else if (index === 3) {
                        gbpRate = rate
                    }
                });
                localStorage.setItem(constant.INR_RATE, inrRate)
                localStorage.setItem(constant.EUR_RATE, eurRate)
                localStorage.setItem(constant.USD_RATE, usdRate)
                localStorage.setItem(constant.GBP_RATE, gbpRate)
            });
        }
        getBrowserCurrency();
        return () => {
            currencyMount = true
        }
    }, [])

    useEffect(() => {
        let shopMounted = false;
        async function getShopFilter() {
            let filter_data = await get(productfilter)
            if (filter_data.status && !shopMounted) {
                store.dispatch({ type: STORE_SHOP_FILTER, payload: filter_data.data.items })
            }
        }
        getShopFilter();
        return () => {
            shopMounted = true;
        }
    }, [])


    return (
        <>
            <Modal show={isInternetDown} fullscreen>
                <Modal.Body>
                    <div className='h-100 d-flex align-items-center justify-content-center'>
                        <div>
                            <div className='text-center'>
                                <BiWifiOff className='h1' />
                            </div>
                            <div className='h3 text-center'>No Internet Connection</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <SocialMediaRoute path="/shop/product/:postId/:shareId" exact component={ProductDetails} />
                    <SocialMediaRoute path="/contests/detail/:postId/:shareId" exact component={ContestView} />
                    <SocialMediaRoute path="/quizzes/detail/:postId/:shareId" exact component={QuizDetails} />
                    <SocialMediaRoute path="/surveys/detail/:postId/:shareId" exact component={TakeSurvey} />
                    <SocialMediaRoute path="/vlog/:postId/:shareId" exact component={BlogDetails} />
                    <SocialMediaRoute path="/wallet/voucher/detail/:postId/:shareId" exact component={Voucherlist} />
                    <SocialMediaRoute path="/event/:postId/:shareId" exact component={EventDetails} />
                    <SocialMediaRoute path="/reels/:postId/:shareId" exact component={Reels} />
                    <SocialMediaRoute path="/blog/:postId/:shareId" exact component={BlogDetails} />
                    <SocialMediaRoute path="/announcement/:postId/:shareId" exact component={Default} />
                    <ScratchCardRoute path="/scratch/:postId" exact component={Default} />
                    <ScratchCardRoute path="/scratch/:postId/:shareId" exact component={Default} />
                    <SocialMediaRoute path="/entry/detail/:entryId/:shareId" exact component={ContestView} />
                    <Route path="/auth" component={Simple}></Route>
                    <Route path="/resetPassword" component={Simple}></Route>
                    <Route path="/shop" component={ShopbuzzRouter}></Route>
                    <Route path="/dishreviews" component={ShopbuzzRouter} />
                    <Route path="/chefreviews" component={ShopbuzzRouter} />
                    <Route path="/board" component={CustomBoard}></Route>
                    <Route path="/group" component={GroupBoard}></Route>
                    <Route path='/chat' component={ChatBoard}></Route>
                    <Route path='/event' component={EventBoard}></Route>
                    <Route path='/wallet' component={WalletBoard}></Route>
                    <Route path='/help' component={HelpBoard}></Route>
                    <Route path='/tutorial' component={TutorialBoard}></Route>
                    <Route path='/mobile-widgets' exact component={MobileSideBar} />
                    <Route path='/social/import-content' component={Importcontent}></Route>
                    <PrivateRoute path="/profile/verifyEmail/:shareId" exact component={Emailverification} />
                    <PrivateRoute path="/profile/verifyEmail" exact component={Emailverification} />
                    <Route path="/setupPassword/:profileId/:otpCode" exact component={Default}></Route>
                    <Route path='/qrform' exact component={Prototypescreen} />
                    <Route path='/generateqr' exact component={Generateqr} />
                    <Route path="/unsubscribe/:shareId" exact component={Unsubscribe} />
                    <Route path="/unsubscribe" exact component={Unsubscribe} />
                    <Route path="/" component={Default}></Route>
                </Switch>
            </Suspense>

            <PopUpModal />
        </>
    )


}

export default IndexRouters